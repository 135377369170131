import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Location } from '@reach/router';
import { navigate } from 'gatsby';
import pose from 'react-pose';
import { useLang, replaceLang } from './wrapper-lang';
import { IconButton } from '../components/icon-button';
import { useZone } from 'use-zone-breakpoints';
const LANG_NAME = {
    한국어: 'ko',
    ENG: 'en',
    日本語: 'ja',
    普通话: 'zh',
};
const StyledLi = styled.li `
  position: relative;
  color: ${({ theme }) => theme.fg};
  opacity: ${({ selected }) => (selected ? 0.4 : 1)};
  left: 0.25rem;
  padding: 0.25rem 0;
  font-weight: bold;
  transition: all 0.3s ease;
  transform: translateX(-0.25rem);

  &:hover {
    opacity: 1;
    transform: translateX(0);
  }
`;
const PoseButtonContainer = pose.div({
    open: {
        opacity: 0.4,
        applyAtStart: {
            display: 'block',
        },
    },
    close: {
        opacity: 1,
        applyAtStart: {
            display: 'block',
        },
    },
    mobile: {
        opacity: 0,
        applyAtEnd: {
            display: 'none',
        },
    },
});
const PoseUl = pose.ul({
    open: {
        opacity: 1,
        y: 0,
        applyAtStart: {
            display: 'block',
        },
    },
    close: {
        opacity: 0,
        y: 10,
        applyAtEnd: {
            display: 'none',
        },
    },
    mobile: {
        opacity: 1,
        y: 0,
        applyAtStart: {
            display: 'block',
        },
    },
});
export const LangSelector = () => {
    const { currentLang, changeLang } = useLang();
    const [isOpen, setOpen] = useState(false);
    const zone = useZone();
    const toggleOpen = () => {
        const shouldOpen = !isOpen;
        setOpen(shouldOpen);
    };
    const handleChangeLang = ({ pathname, nextLang }) => () => {
        const newPath = replaceLang({
            pathname,
            nextLang,
        });
        navigate(newPath);
        toggleOpen();
        changeLang(nextLang);
    };
    const pose = zone < 2 ? 'mobile' : isOpen ? 'open' : 'close';
    return (<Location>
      {({ location }) => (<React.Fragment>
          <PoseButtonContainer pose={pose}>
            <IconButton onClick={() => toggleOpen()} iconName="translate" size={1.5}/>
          </PoseButtonContainer>
          <PoseUl pose={pose}>
            {Object.entries(LANG_NAME).map(entry => {
        const [name, lang] = entry;
        return (<StyledLi onClick={handleChangeLang({
            nextLang: lang,
            pathname: location.pathname + location.search,
        })} key={lang} selected={currentLang === lang}>
                  {name}
                </StyledLi>);
    })}
          </PoseUl>
        </React.Fragment>)}
    </Location>);
};
