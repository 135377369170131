import * as React from 'react';
import styled from '@emotion/styled';
import { mqs } from './style-utils';
const Styledlogo = styled.div `
  color: ${({ theme }) => theme.fg};
  z-index: 1000;
  width: 3.5rem;
  height: 3.25rem;

  ${mqs[0]} {
    width: 2rem;
  }

  ${mqs[1]} {
    width: 2.75rem;
  }

  ${mqs[2]} {
    width: 3.25rem;
  }
`;
export const Logo = props => (<Styledlogo {...props}>
    <svg viewBox="0 0 65 53" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.847 27.43c14.31 0 25.95 11.383 26.34 25.57H24.69C24.3 41.292 14.674 31.919 2.847 31.919c-.966 0-1.915.069-2.847.19v-4.523a26.69 26.69 0 0 1 2.847-.155zm26.905 0c14.31 0 25.95 11.383 26.34 25.57h-4.497c-.38-11.46-9.615-20.678-21.098-21.063 4.614 5.82 7.434 13.12 7.598 21.063h-4.454c-.393-16.616-14.05-30.014-30.795-30.014-.96 0-1.908.05-2.846.137v-4.457c.94-.075 1.887-.124 2.846-.124 2.353 0 4.652.234 6.877.675a8.112 8.112 0 0 0-7.8-5.884c-.664 0-1.274.008-1.923.14V9.035c.627-.096 1.27-.146 1.923-.146 6.623 0 12.063 5.134 12.543 11.623a35.11 35.11 0 0 1 4.457 1.915c.02-.326.035-.653.035-.984 0-9.373-7.642-16.999-17.035-16.999-.65 0-1.291.04-1.923.112V.09C.634.034 1.275 0 1.923 0c11.849 0 21.488 9.62 21.488 21.443 0 1.2-.109 2.372-.3 3.518a35.482 35.482 0 0 1 3.317 2.637 45.613 45.613 0 0 1 3.324-.167zM65 0V53h-4.454c-.393-16.615-14.05-30.013-30.795-30.013-.96 0-1.908.05-2.846.136V0h4.454v18.582c1.512.068 2.997.238 4.454.49V0h4.453v20.143a35.214 35.214 0 0 1 11.373 6.028V0h4.453v30.38a35.353 35.353 0 0 1 4.454 6.233V0H65z" fill="currentColor" fillRule="evenodd"/>
    </svg>
  </Styledlogo>);
