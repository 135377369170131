import * as React from 'react';
import pose, { PoseGroup } from 'react-pose';
const Transition = pose.div({
    enter: {
        opacity: 1,
        beforeChildren: true,
        transition: {
            duration: 0,
        },
    },
    exit: {
        opacity: 1,
        afterChildren: true,
        transition: {
            duration: 0,
        },
    },
});
export const TransitionWrapper = ({ children, location }) => {
    return (<PoseGroup>
      <Transition key={location.pathname}>{children}</Transition>
    </PoseGroup>);
};
