import * as React from 'react';
import { ZoneManager } from 'use-zone-breakpoints';
import { ThemeManager } from './wrapper-theme';
import { PositionManager } from './wrapper-positions';
import { Layout } from './layout';
import { GlobalStyles } from './style-global';
import { BREAKPOINTS } from '../config';
import { TransitionWrapper } from './wrapper-transition';
import { LangManager } from './wrapper-lang';
import { detectIE, detectMobile } from '../utils';
export const RootWrapper = ({ children }) => (<LangManager>
    <ZoneManager breakpoints={BREAKPOINTS} defaultZone={2}>
      <ThemeManager>
        <PositionManager>{children}</PositionManager>
      </ThemeManager>
    </ZoneManager>
  </LangManager>);
export const PageWrapper = ({ children, ...props }) => {
    const { location } = props;
    const noPageTransition = detectIE() || detectMobile();
    return (<Layout location={location}>
      <GlobalStyles />
      {noPageTransition ? children : (<TransitionWrapper location={location}>
        {children}
      </TransitionWrapper>)}
    </Layout>);
};
