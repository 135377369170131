import * as React from 'react';
import { css, Global } from '@emotion/core';
import { withTheme } from 'emotion-theming';
export const makeBackground = (theme) => css `
  body {
    background: ${theme.bg};
    color: ${theme.fg};
    transition: background 0.3s ease;
    word-break: keep-all;
  }
`;
export const GlobalStyles = withTheme(({ theme }) => (<Global styles={[makeBackground(theme)]}/>));
