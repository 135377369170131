const getSize = (size, gridSize) => (size / gridSize) * 100;
export const getWidthSize = ({ begin, end, gridSize }) => {
    const width = Math.abs(end - begin);
    return getSize(width, gridSize);
};
export const getOffsetSize = ({ begin, end, gridSize }) => {
    const size = Math.min(begin, end);
    return getSize(size, gridSize);
};
export const getTextDirection = ({ begin, end, gridSize, }) => {
    const blockSize = Math.abs(begin - end);
    if (blockSize === gridSize)
        return 'bottom';
    const rEnd = Math.max(begin, end);
    return rEnd === gridSize ? 'left' : 'right';
};
export const shouldSquare = ({ begin, end, gridSize }) => gridSize === 3 ? true : Math.abs(begin - end) === 1;
