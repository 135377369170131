import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { LogoKakaoEnt } from './logo-kakao';
const StyledFooter = styled.footer `
  position: absolute;
  width: 100%;
  bottom: 2rem;
  text-align: center;
  box-sizing: border-box;
  padding: 0;

  .footer-copyright {
    font-size: 0.6rem;
  }

  .footer-kakao {
    width: 149px;
    height: 20px;
    margin-bottom: 1rem;
  }

  .footer-sites {
    font-size: 0.8rem;
    margin-bottom: 1rem;

    p {
      margin-bottom: 0.5rem;
      font-weight: bold;
      opacity: 0.8;
    }

    ul li {
      display: inline-block;
    }

    ul li:after {
      content: "·";
      position: relative;
      display: inline-block;
      padding: 0 0.5rem;
    }

    ul li:last-of-type:after {
      content: "";
      padding: 0;
    }

    a {
      color: ${({ theme }) => theme.fg};
      opacity: 0.6;
      transition: opacity 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
export const Footer = () => {
    const { fam } = useStaticQuery(graphql `
    query FamilySite {
      fam: contentJson(type: { eq: "family-site" }) {
        title
        siteFamily {
          name
          url
        }
      }
    }
  `);
    return (
    // @ts-ignore
    <StyledFooter>
      <LogoKakaoEnt />

      <p className="footer-copyright">
        {`©${new Date().getFullYear()}. Copyright © BH Entertainment. All rights reserved`}
      </p>
    </StyledFooter>);
};
