import * as React from 'react';
import styled from '@emotion/styled';
import { useZone } from 'use-zone-breakpoints';
import { mqs } from '../style-utils';
import { GRIDSIZES } from '../../config';
import { getWidthSize, getOffsetSize } from './utils';
export const FlexGrid = styled.div `
  position: relative;
  display: flex;
  width: 100%;
`;
export const FlexItem = styled.div `
  flex: 0 1 auto;
  width: ${props => getWidthSize(props)}%;
  margin-left: ${props => getOffsetSize(props)}%;
  transition: all 0.5s ease;
`;
export const FlexOneOffset = styled.div `
  flex: 0 1 auto;
  ${mqs[0]} {
    width: 66.66%;
    margin-left: ${({ direction }) => (direction === 'left' ? 0 : 33.33)}%;
  }

  ${mqs[1]} {
    width: 66.66%;
    margin-left: ${({ direction }) => (direction === 'left' ? 0 : 33.33)}%;
  }

  ${mqs[2]} {
    width: 75%;
    margin-left: ${({ direction }) => (direction === 'left' ? 0 : 25)}%;
  }

  ${mqs[3]} {
    width: 75%;
    margin-left: ${({ direction }) => (direction === 'left' ? 0 : 25)}%;
  }

  ${mqs[4]} {
    width: 80%;
    margin-left: ${({ direction }) => (direction === 'left' ? 0 : 20)}%;
  }
`;
export const FlexContent = styled.div `
  flex: 0 1 auto;

  ${mqs[0]} {
    width: 66.66%;
    margin-left: 33.33%;
  }

  ${mqs[1]} {
    width: 66.66%;
    margin-left: 33.33%;
  }

  ${mqs[2]} {
    width: 50%;
    margin-left: 25%;
  }

  ${mqs[3]} {
    width: 50%;
    margin-left: 25%;
  }

  ${mqs[4]} {
    width: 60%;
    margin-left: 20%;
  }
`;
const StyledDecoyGrid = styled.div `
  position: absolute;
  z-index: -1;
  display: flex;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const DecoyCol = styled.div `
  height: 100%;
  flex: 1 1 auto;
  border-left: 1px solid ${({ theme }) => theme.line};
  &:last-child {
    border-right: 1px solid ${({ theme }) => theme.line};
  }
`;
export const DecoyGrid = () => {
    const zone = useZone();
    const gridSize = GRIDSIZES[zone];
    return (<StyledDecoyGrid>
      {Array(gridSize)
        .fill(0)
        .map((_, i) => (<DecoyCol key={i}/>))}
    </StyledDecoyGrid>);
};
