import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import pose from 'react-pose';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useTheme } from './wrapper-theme';
import { mqs } from '../components/style-utils';
import { Icon } from './icons';
const buttonReset = css `
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
`;
const ThemeSwitcher = () => {
    const { changeTheme, currentTheme } = useTheme();
    const handleClick = () => changeTheme();
    return (
    // @ts-ignore
    <button css={buttonReset} onClick={handleClick}>
      <Icon iconName="switch" rotate={currentTheme === 'light' ? 180 : 0}/>
    </button>);
};
const PoseSocialMedia = pose.div({
    hidden: {
        opacity: 0,
        y: 20,
        transition: {
            // duration: 300,
            // type: 'tween',
            ease: 'linear',
        },
        applyAtEnd: {
            display: 'none',
        },
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            // duration: 300,
            // type: 'tween',
            ease: 'linear',
        },
        applyAtStart: {
            display: 'block',
        },
    },
});
const StyledSocialMedia = styled(PoseSocialMedia) `
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  font-size: 0.625rem;
  box-sizing: border-box;
  z-index: 800;

  nav {
    position: absolute;
    right: 0.5rem;
    bottom: 1rem;
  }

  nav hr {
    margin: 0;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.line};
  }

  nav ul li {
    display: block;
    margin: 1rem;
    text-align: right;
    font-size: 1rem;
  }

  ${mqs[0]} {
    nav ul li {
      padding: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${({ theme }) => theme.bg};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    }
  }

  ${mqs[1]} {
    nav ul li {
      padding: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: ${({ theme }) => theme.bg};
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
    }
  }
`;
export const SocialMedia = ({ hideNav }) => {
    const data = useStaticQuery(graphql `
    query Social {
      social: contentJson(type: { eq: "sns" }) {
        social {
          type
          url
        }
      }
    }
  `);
    const social = data.social.social;
    return (<StyledSocialMedia pose={hideNav ? 'hidden' : 'show'}>
      <nav>
        <ul>
          <li>
            <ThemeSwitcher />
          </li>
          <hr />
          {social &&
        social.map(item => (<li key={item.type}>
                <a href={item.url} target="blank">
                  <Icon iconName={item.type}/>
                </a>
              </li>))}
        </ul>
      </nav>
    </StyledSocialMedia>);
};
