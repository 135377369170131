import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import Walker from '@d4rek/walker';
import { useZone } from 'use-zone-breakpoints';
import { GRIDSIZES } from '../config';
import { shuffle } from '../utils';
const MAX_LENGTH = 60;
const initialPos = {
    positions: [],
    normalPos: [],
    order: [],
    shuffleOrder: () => { },
    // setOrder: (arg: any[]) => {},
    changePosition: (arg) => { },
    gridSize: 4,
};
const makeArray = length => Array(length)
    .fill('')
    .map((_, i) => i);
const zzgg = (length, gridSize) => Array(length)
    .fill('')
    .map((_, i) => {
    const begin = i % 2 === 0 ? 1 : 0;
    const end = i % 2 === 0 ? gridSize : gridSize - 1;
    return { begin, end };
});
const rgg = (length, gridSize) => Array(length)
    .fill('')
    .map(_ => {
    let begin = 1;
    let end = gridSize - 1;
    if (gridSize === 3) {
        begin = 0;
        end = 3;
    }
    return {
        begin,
        end,
    };
});
const walk = (gridSize) => {
    const walkerFirstTwo = new Walker({ gridSize: gridSize - 1, maxBlockSize: 3 });
    const walker = new Walker({ gridSize, maxBlockSize: 3 });
    const firstTwo = walkerFirstTwo.walk(2).map(({ begin, end }) => ({ begin: begin + 1, end: end + 1 }));
    const remain = walker.walk(MAX_LENGTH, {
        fromPos: firstTwo[firstTwo.length - 1],
    });
    return firstTwo.concat(remain);
};
const PositionContext = React.createContext(initialPos);
export const usePositions = () => useContext(PositionContext);
export const PositionManager = ({ children }) => {
    const defaultGrid = rgg(MAX_LENGTH, 4);
    const [positions, setPositions] = useState(defaultGrid);
    const [order, setOrder] = useState(shuffle(makeArray(MAX_LENGTH)));
    const [normalPos, setNormalPos] = useState(defaultGrid);
    const zone = useZone();
    const gridSize = GRIDSIZES[zone];
    const shuffleOrder = () => {
        const shuffled = shuffle(order);
        setOrder(shuffled);
    };
    const changePosition = zone => {
        const gridSize = GRIDSIZES[zone];
        // randomize position
        let pos = [];
        if (gridSize === 3) {
            pos = zzgg(MAX_LENGTH, gridSize);
        }
        else {
            pos = walk(gridSize);
        }
        setPositions(pos);
        // randomize gridSize
        const normalPos = rgg(MAX_LENGTH, gridSize);
        setNormalPos(normalPos);
    };
    useEffect(() => {
        changePosition(zone);
    }, [zone]);
    return (<PositionContext.Provider value={{
        order,
        shuffleOrder,
        // setOrder,
        gridSize,
        normalPos,
        positions,
        changePosition,
    }}>
      {children}
    </PositionContext.Provider>);
};
