/* eslint-disable max-len */
import * as React from 'react';
import styled from '@emotion/styled';
const StyledLogoContainer = styled.div `
  color: ${({ theme }) => theme.fg};
  width: 75%;
  min-width: 16rem;
  max-width: 24rem;
  margin: 0 auto;
  
  & > svg {
    width: 100%;
    height: auto;
  }
`;
export const LogoKakaoEnt = () => {
    return <StyledLogoContainer>
    <svg viewBox="0 0 200 38" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.2019 20.5068V20.0545H36.6732C35.8887 20.0545 35.3855 20.2372 35.3855 20.794C35.3855 21.177 35.5902 21.4294 36.1019 21.4294C36.7329 21.4294 37.2019 21.09 37.2019 20.5068ZM34.3623 20.8377C34.3623 19.7758 35.3685 19.3928 36.6476 19.3928H37.2019V19.1581C37.2019 18.5487 36.9973 18.2441 36.3918 18.2441C35.8545 18.2441 35.5902 18.5052 35.539 18.9579H34.5413C34.6266 17.8962 35.4537 17.4524 36.4515 17.4524C37.4492 17.4524 38.2337 17.8701 38.2337 19.1146V22.0993H37.2189V21.5423C36.929 21.934 36.5367 22.1864 35.8545 22.1864C35.0359 22.1864 34.3623 21.7862 34.3623 20.8377Z"/>
      <path d="M41.6205 15.8769H42.7549V18.697L45.0917 15.8683H46.3112L43.7867 18.845L46.4393 22.1001H45.1003L42.7549 19.193V22.1001H41.6205V15.8769Z"/>
      <path d="M49.5879 20.5068V20.0545H49.0592C48.2747 20.0545 47.7716 20.2372 47.7716 20.794C47.7716 21.177 47.9762 21.4294 48.4879 21.4294C49.1189 21.4294 49.5879 21.09 49.5879 20.5068ZM46.7483 20.8377C46.7483 19.7758 47.7546 19.3928 49.0336 19.3928H49.5879V19.1581C49.5879 18.5487 49.3833 18.2441 48.7778 18.2441C48.2406 18.2441 47.9762 18.5052 47.9251 18.9579H46.9274C47.0127 17.8962 47.8398 17.4524 48.8374 17.4524C49.8351 17.4524 50.6196 17.8701 50.6196 19.1146V22.0993H49.6049V21.5423C49.3151 21.934 48.9227 22.1864 48.2406 22.1864C47.4221 22.1864 46.7483 21.7862 46.7483 20.8377Z"/>
      <path d="M51.7632 15.441H52.7952V19.5493L54.39 17.5473H55.5158L53.7845 19.6364L55.6608 22.0995H54.4667L52.7952 19.8625V22.0995H51.7632V15.441Z"/>
      <path d="M58.7569 20.5068V20.0545H58.2282C57.4437 20.0545 56.9406 20.2372 56.9406 20.794C56.9406 21.177 57.1452 21.4294 57.6569 21.4294C58.2879 21.4294 58.7569 21.09 58.7569 20.5068ZM55.9173 20.8377C55.9173 19.7758 56.9234 19.3928 58.2026 19.3928H58.7569V19.1581C58.7569 18.5487 58.5523 18.2441 57.9468 18.2441C57.4095 18.2441 57.1452 18.5052 57.094 18.9579H56.0963C56.1816 17.8962 57.0087 17.4524 58.0064 17.4524C59.0041 17.4524 59.7886 17.8701 59.7886 19.1146V22.0993H58.7739V21.5423C58.484 21.934 58.0917 22.1864 57.4095 22.1864C56.5909 22.1864 55.9173 21.7862 55.9173 20.8377Z"/>
      <path d="M64.1548 19.8542V19.7932C64.1548 18.8534 63.6944 18.2791 62.9269 18.2791C62.1594 18.2791 61.6904 18.8447 61.6904 19.7845V19.8542C61.6904 20.7855 62.1423 21.3685 62.9269 21.3685C63.6944 21.3685 64.1548 20.7855 64.1548 19.8542ZM60.633 19.8716V19.8019C60.633 18.3923 61.6137 17.4524 62.9269 17.4524C64.2315 17.4524 65.2121 18.3834 65.2121 19.7758V19.8456C65.2121 21.264 64.2315 22.1864 62.9183 22.1864C61.6051 22.1864 60.633 21.2554 60.633 19.8716Z"/>
      <path d="M68.0042 15.8744H71.8165V16.7883H69.1384V18.4943H71.2877V19.3645H69.1384V21.1838H71.9615V22.0976H68.0042V15.8744Z"/>
      <path d="M72.6864 17.5481H73.7182V18.2704C73.9314 17.8266 74.426 17.4524 75.1593 17.4524C76.0547 17.4524 76.6857 17.983 76.6857 19.2362V22.0994H75.6538V19.332C75.6538 18.6447 75.3811 18.3312 74.767 18.3312C74.1872 18.3312 73.7182 18.6968 73.7182 19.4191V22.0994H72.6864V17.5481Z"/>
      <path d="M77.8281 20.8275V18.3386H77.2312V17.5467H77.8281V16.5459H78.8599V17.5467H79.8406V18.3386H78.8599V20.7318C78.8599 21.1322 79.0475 21.3149 79.3716 21.3149C79.5762 21.3149 79.7297 21.2888 79.8746 21.2278V22.0632C79.7127 22.1241 79.4909 22.1764 79.1754 22.1764C78.2886 22.1764 77.8281 21.6892 77.8281 20.8275Z"/>
      <path d="M83.9344 19.3494C83.8833 18.5661 83.491 18.2183 82.8515 18.2183C82.2289 18.2183 81.8112 18.6445 81.7087 19.3494H83.9344ZM80.6344 19.8716V19.8019C80.6344 18.3923 81.5808 17.4524 82.8515 17.4524C83.96 17.4524 84.9577 18.1222 84.9577 19.7496V20.0545H81.6917C81.7259 20.9159 82.1521 21.4033 82.9198 21.4033C83.5422 21.4033 83.8661 21.1508 83.9429 20.7071H84.9407C84.7957 21.6644 84.0368 22.1864 82.8942 22.1864C81.5808 22.1864 80.6344 21.3076 80.6344 19.8716Z"/>
      <path d="M85.8385 17.5466H86.8702V18.3996C87.1517 17.8251 87.5696 17.4858 88.3199 17.4768V18.4604C87.4161 18.4691 86.8702 18.7649 86.8702 19.731V22.0979H85.8385V17.5466Z"/>
      <path d="M89.393 20.8275V18.3386H88.7962V17.5467H89.393V16.5459H90.4247V17.5467H91.4054V18.3386H90.4247V20.7318C90.4247 21.1322 90.6124 21.3149 90.9364 21.3149C91.1411 21.3149 91.2947 21.2888 91.4396 21.2278V22.0632C91.2775 22.1241 91.0559 22.1764 90.7404 22.1764C89.8534 22.1764 89.393 21.6892 89.393 20.8275Z"/>
      <path d="M94.8864 20.5068V20.0545H94.3577C93.5732 20.0545 93.0701 20.2372 93.0701 20.794C93.0701 21.177 93.2747 21.4294 93.7864 21.4294C94.4174 21.4294 94.8864 21.09 94.8864 20.5068ZM92.0468 20.8377C92.0468 19.7758 93.0531 19.3928 94.3321 19.3928H94.8864V19.1581C94.8864 18.5487 94.6818 18.2441 94.0763 18.2441C93.5392 18.2441 93.2747 18.5052 93.2236 18.9579H92.2259C92.3112 17.8962 93.1383 17.4524 94.1361 17.4524C95.1338 17.4524 95.9183 17.8701 95.9183 19.1146V22.0993H94.9034V21.5423C94.6136 21.934 94.2212 22.1864 93.5392 22.1864C92.7206 22.1864 92.0468 21.7862 92.0468 20.8377Z"/>
      <path d="M97.258 17.546H98.2899V22.0981H97.258V17.546ZM97.1471 16.197C97.1471 15.8486 97.42 15.5875 97.7611 15.5875C98.1024 15.5875 98.3752 15.8486 98.3752 16.197C98.3752 16.5362 98.1024 16.7975 97.7611 16.7975C97.42 16.7975 97.1471 16.5362 97.1471 16.197Z"/>
      <path d="M99.5004 17.5481H100.532V18.2704C100.745 17.8266 101.24 17.4524 101.973 17.4524C102.869 17.4524 103.5 17.983 103.5 19.2362V22.0994H102.468V19.332C102.468 18.6447 102.195 18.3312 101.581 18.3312C101.001 18.3312 100.532 18.6968 100.532 19.4191V22.0994H99.5004V17.5481Z"/>
      <path d="M104.643 17.5481H105.675V18.2442C105.889 17.8178 106.375 17.4524 107.031 17.4524C107.611 17.4524 108.072 17.7047 108.294 18.2705C108.635 17.7047 109.249 17.4524 109.803 17.4524C110.622 17.4524 111.287 17.9658 111.287 19.2192V22.1001H110.255V19.2889C110.255 18.6186 109.974 18.3313 109.462 18.3313C108.95 18.3313 108.481 18.6794 108.481 19.376V22.1001H107.449V19.2889C107.449 18.6186 107.159 18.3313 106.656 18.3313C106.145 18.3313 105.675 18.6794 105.675 19.376V22.1001H104.643V17.5481Z"/>
      <path d="M115.434 19.3494C115.383 18.5661 114.99 18.2183 114.351 18.2183C113.728 18.2183 113.31 18.6445 113.208 19.3494H115.434ZM112.134 19.8716V19.8019C112.134 18.3923 113.08 17.4524 114.351 17.4524C115.459 17.4524 116.457 18.1222 116.457 19.7496V20.0545H113.191C113.225 20.9159 113.652 21.4033 114.419 21.4033C115.041 21.4033 115.366 21.1508 115.442 20.7071H116.44C116.295 21.6644 115.536 22.1864 114.393 22.1864C113.08 22.1864 112.134 21.3076 112.134 19.8716Z"/>
      <path d="M117.335 17.5481H118.367V18.2704C118.58 17.8266 119.074 17.4524 119.808 17.4524C120.703 17.4524 121.334 17.983 121.334 19.2362V22.0994H120.302V19.332C120.302 18.6447 120.029 18.3312 119.415 18.3312C118.836 18.3312 118.367 18.6968 118.367 19.4191V22.0994H117.335V17.5481Z"/>
      <path d="M122.647 20.8275V18.3386H122.05V17.5467H122.647V16.5459H123.679V17.5467H124.66V18.3386H123.679V20.7318C123.679 21.1322 123.867 21.3149 124.191 21.3149C124.396 21.3149 124.549 21.2888 124.694 21.2278V22.0632C124.532 22.1241 124.31 22.1764 123.995 22.1764C123.108 22.1764 122.647 21.6892 122.647 20.8275Z"/>
      <path d="M127.349 19.0263V18.9566C127.349 17.0852 128.611 15.7798 130.309 15.7798C131.707 15.7798 132.79 16.4934 132.927 17.9558H131.775C131.648 17.1287 131.196 16.7022 130.317 16.7022C129.243 16.7022 128.543 17.5552 128.543 18.9479V19.0176C128.543 20.4188 129.2 21.2457 130.326 21.2457C131.17 21.2457 131.767 20.8105 131.886 19.9315H132.995C132.816 21.4286 131.775 22.1857 130.326 22.1857C128.415 22.1857 127.349 20.941 127.349 19.0263Z"/>
      <path d="M137.07 19.8542V19.7932C137.07 18.8534 136.61 18.2791 135.842 18.2791C135.075 18.2791 134.606 18.8447 134.606 19.7845V19.8542C134.606 20.7855 135.058 21.3685 135.842 21.3685C136.61 21.3685 137.07 20.7855 137.07 19.8542ZM133.548 19.8716V19.8019C133.548 18.3923 134.529 17.4524 135.842 17.4524C137.147 17.4524 138.128 18.3834 138.128 19.7758V19.8456C138.128 21.264 137.147 22.1864 135.834 22.1864C134.52 22.1864 133.548 21.2554 133.548 19.8716Z"/>
      <path d="M139.018 17.5481H140.05V18.2442C140.263 17.8178 140.749 17.4524 141.406 17.4524C141.986 17.4524 142.446 17.7047 142.668 18.2705C143.009 17.7047 143.623 17.4524 144.178 17.4524C144.996 17.4524 145.662 17.9658 145.662 19.2192V22.1001H144.63V19.2889C144.63 18.6186 144.348 18.3313 143.837 18.3313C143.325 18.3313 142.856 18.6794 142.856 19.376V22.1001H141.824V19.2889C141.824 18.6186 141.534 18.3313 141.031 18.3313C140.519 18.3313 140.05 18.6794 140.05 19.376V22.1001H139.018V17.5481Z"/>
      <path d="M150.216 19.8546V19.7849C150.216 18.7753 149.704 18.2792 149.03 18.2792C148.314 18.2792 147.802 18.7753 147.802 19.7849V19.8546C147.802 20.873 148.288 21.3517 149.039 21.3517C149.798 21.3517 150.216 20.8294 150.216 19.8546ZM146.804 17.5481H147.836V18.2792C148.084 17.844 148.638 17.4524 149.295 17.4524C150.412 17.4524 151.273 18.2965 151.273 19.7675V19.837C151.273 21.2994 150.446 22.1872 149.295 22.1872C148.587 22.1872 148.075 21.8304 147.836 21.3865V23.6582H146.804V17.5481Z"/>
      <path d="M154.677 20.5068V20.0545H154.148C153.364 20.0545 152.861 20.2372 152.861 20.794C152.861 21.177 153.065 21.4294 153.577 21.4294C154.208 21.4294 154.677 21.09 154.677 20.5068ZM151.837 20.8377C151.837 19.7758 152.844 19.3928 154.123 19.3928H154.677V19.1581C154.677 18.5487 154.472 18.2441 153.867 18.2441C153.33 18.2441 153.065 18.5052 153.014 18.9579H152.016C152.102 17.8962 152.929 17.4524 153.927 17.4524C154.924 17.4524 155.709 17.8701 155.709 19.1146V22.0993H154.694V21.5423C154.404 21.934 154.012 22.1864 153.33 22.1864C152.511 22.1864 151.837 21.7862 151.837 20.8377Z"/>
      <path d="M156.852 17.5481H157.884V18.2704C158.097 17.8266 158.592 17.4524 159.325 17.4524C160.221 17.4524 160.852 17.983 160.852 19.2362V22.0994H159.82V19.332C159.82 18.6447 159.547 18.3312 158.933 18.3312C158.353 18.3312 157.884 18.6968 157.884 19.4191V22.0994H156.852V17.5481Z"/>
      <path d="M163.171 21.6371L161.406 17.547H162.523L163.709 20.4796L164.8 17.547H165.806L163.376 23.6562H162.361L163.171 21.6371Z"/>
    </svg>

  </StyledLogoContainer>;
};
