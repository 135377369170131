import * as React from 'react';
import { useContext, useState } from 'react';
import { ThemeProvider } from 'emotion-theming';
import { theme } from './theme';
const initThemeState = {
    currentTheme: 'light',
    changeTheme: () => { },
};
const ThemeContext = React.createContext(initThemeState);
export const useTheme = () => useContext(ThemeContext);
export const ThemeManager = ({ children }) => {
    const [currentTheme, setTheme] = useState('light');
    const changeTheme = () => {
        const nextTheme = currentTheme === 'light' ? 'dark' : 'light';
        setTheme(nextTheme);
    };
    return (<ThemeProvider theme={theme[currentTheme]}>
      <ThemeContext.Provider value={{ currentTheme, changeTheme }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>);
};
