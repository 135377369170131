import * as React from 'react';
import { useState } from 'react';
import pose from 'react-pose';
import styled from '@emotion/styled';
import { IconButton } from '../icon-button';
import { TRANSITION, MenuUl } from './nav.common';
const Div = pose.div({
    hidden: {
        opacity: 0,
        staggerChildren: 25,
        transition: TRANSITION,
        applyAtEnd: {
            display: 'none',
        },
    },
    show: {
        opacity: 1,
        staggerChildren: 25,
        transition: TRANSITION,
        applyAtStart: {
            display: 'block',
        },
    },
});
const PoseContainer = styled(Div) `
  position: relative;
`;
const Button = pose.div({
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    },
});
const PoseButton = styled(Button) `
  position: absolute;
`;
const PoseCloseButton = styled(Button) `
  position: fixed;
  left: 1.5rem;
  bottom: 1.5rem;
`;
const Overlay = pose.div({
    hidden: {
        opacity: 0,
        applyAtEnd: {
            display: 'none',
        },
    },
    show: {
        opacity: 0.8,
        applyAtStart: {
            display: 'block',
        },
    },
});
const PoseOverlay = styled(Overlay) `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: ${({ theme }) => theme.bg};
`;
export const MobileNav = ({ hideNav, ...props }) => {
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = (nextState) => {
        let shouldOpen = typeof nextState === 'undefined' ? !isOpen : nextState;
        setOpen(shouldOpen);
    };
    const close = () => toggleOpen(false);
    const buttonPose = hideNav ? 'hidden' : isOpen ? 'hidden' : 'show';
    const menuPose = hideNav ? 'hidden' : isOpen ? 'show' : 'hidden';
    return (<React.Fragment>
      <PoseButton pose={buttonPose}>
        <IconButton onClick={() => toggleOpen()} iconName="menu" size={2}/>
      </PoseButton>
      <PoseContainer {...props} pose={menuPose}>
        <MenuUl onClick={close}/>
      </PoseContainer>
      <PoseCloseButton pose={menuPose}>
        <IconButton onClick={close} iconName="close" size={2}/>
      </PoseCloseButton>
      <PoseOverlay onClick={close} pose={menuPose}/>
    </React.Fragment>);
};
