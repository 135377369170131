import * as React from 'react';
import { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { I18N_CONFIG } from '../../config/helpers/i18n';
/**
 * Retrieve current lang from localStorage, if any
 */
const getCurrentLang = () => {
    if (typeof window === 'undefined' || !window.localStorage) {
        return I18N_CONFIG.canon;
    }
    let currentLang = localStorage.getItem('current-lang');
    if (!currentLang) {
        localStorage.setItem('current-lang', I18N_CONFIG.canon);
        return I18N_CONFIG.canon;
    }
    return currentLang;
};
const LangContext = React.createContext({
    currentLang: I18N_CONFIG.canon,
    changeLang: () => { },
});
export const useLang = () => useContext(LangContext);
/**
 * Manager
 */
export const LangManager = ({ children }) => {
    const [currentLang, setCurrentLang] = useState(getCurrentLang());
    const changeLang = (currentLang) => {
        setCurrentLang(currentLang);
        localStorage.setItem('current-lang', currentLang);
    };
    return (<LangContext.Provider value={{ currentLang, changeLang }}>
      {children}
    </LangContext.Provider>);
};
/**
 * Gatsby Link wrapper
 * append language to current page
 */
const I18nLink = React.forwardRef(
// @ts-ignore
({ children, to, ...props }, ref) => {
    const { currentLang } = useLang();
    const prefixedTo = (currentLang === I18N_CONFIG.canon)
        ? to
        : `/${currentLang}${to}`;
    return (
    // @ts-ignore
    <Link ref={ref} to={prefixedTo} {...props}>{children}</Link>);
});
I18nLink.displayName = 'I18nLink';
export { I18nLink as Link };
const I18nNavigate = (to, options) => {
    let currentLang = localStorage.getItem('current-lang');
    const prefixedTo = (currentLang === I18N_CONFIG.canon)
        ? to
        : `/${currentLang}${to}`;
    navigate(prefixedTo, options);
};
export { I18nNavigate as navigate };
export const replaceLang = ({ pathname, nextLang }) => {
    let newPath;
    const isCanon = (nextLang === I18N_CONFIG.canon);
    const normalizedPath = pathname[0] === '/'
        ? pathname
        : `/${pathname}`;
    const [first, maybeCurrentLang, ...rest] = normalizedPath.split('/');
    if (I18N_CONFIG.languages.includes(maybeCurrentLang)) {
        // we're interested if this is a valid language
        newPath = isCanon
            ? [first, ...rest].join('/')
            : [first, nextLang, ...rest].join('/');
    }
    else {
        // otherwise, this is a canon path, no need to redirect
        newPath = isCanon
            ? normalizedPath
            : `/${nextLang}${normalizedPath}`;
    }
    return newPath;
};
