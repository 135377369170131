import React from 'react'
import { RootWrapper, PageWrapper } from './src/components/wrapper'

export const wrapRootElement = ({ element }) => (
  <RootWrapper>{element}</RootWrapper>
)

export const wrapPageElement = ({ element, props }) => (
  <PageWrapper {...props}>{React.cloneElement(element, props)}</PageWrapper>
)
