import * as React from 'react';
export const Facebook = () => (<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.007 0H.993A.993.993 0 0 0 0 .993v16.014c0 .548.445.993.993.993h8.628v-6.961H7.278V8.314H9.62V6.31c0-2.324 1.421-3.59 3.494-3.59.699-.002 1.397.033 2.092.105v2.43h-1.428c-1.13 0-1.35.534-1.35 1.322v1.735h2.7l-.35 2.725h-2.365V18h4.593a.993.993 0 0 0 .993-.993V.993A.993.993 0 0 0 17.007 0z" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const Insta = () => (<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.997.006c2.444 0 2.75.01 3.71.054.957.043 1.611.196 2.184.418a4.41 4.41 0 0 1 1.593 1.038c.5.5.808 1.002 1.038 1.593.222.573.375 1.227.418 2.184.044.96.054 1.266.054 3.71 0 2.443-.01 2.75-.054 3.71-.044.957-.196 1.611-.418 2.183a4.41 4.41 0 0 1-1.038 1.594c-.5.5-1.002.808-1.593 1.038-.573.222-1.227.374-2.184.418-.96.044-1.266.054-3.71.054-2.443 0-2.75-.01-3.71-.054-.957-.044-1.611-.196-2.183-.418A4.41 4.41 0 0 1 1.51 16.49a4.41 4.41 0 0 1-1.038-1.594C.25 14.324.098 13.67.054 12.712.01 11.752 0 11.446 0 9.002 0 6.56.01 6.254.054 5.294.098 4.336.25 3.682.472 3.11A4.41 4.41 0 0 1 1.51 1.516 4.41 4.41 0 0 1 3.104.478C3.676.256 4.33.104 5.288.06c.96-.044 1.266-.054 3.71-.054zm0 1.62c-2.402 0-2.687.01-3.635.053-.878.04-1.354.187-1.671.31-.42.163-.72.358-1.035.673-.314.315-.51.615-.673 1.035-.123.317-.27.793-.31 1.67-.043.949-.052 1.234-.052 3.636s.01 2.687.053 3.635c.04.878.186 1.354.31 1.671.163.42.358.72.672 1.035.315.314.615.51 1.035.673.317.123.793.27 1.67.31.95.043 1.234.052 3.636.052 2.403 0 2.687-.01 3.636-.053.877-.04 1.353-.186 1.67-.31.42-.163.72-.358 1.035-.672.315-.315.51-.615.673-1.035.123-.317.27-.793.31-1.67.043-.95.052-1.234.052-3.636s-.009-2.687-.052-3.636c-.04-.877-.187-1.353-.31-1.67a2.787 2.787 0 0 0-.673-1.035 2.787 2.787 0 0 0-1.035-.673c-.317-.123-.793-.27-1.67-.31-.949-.043-1.233-.052-3.636-.052zM9 11.922A2.921 2.921 0 1 0 9 6.08a2.921 2.921 0 0 0 0 5.842zM9 4.5a4.5 4.5 0 1 1 0 9 4.5 4.5 0 0 1 0-9zm6.188 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0z" fill="currentColor" fillRule="evenodd"/>
  </svg>);
export const Naver = () => (<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.173 13.129l1.81-2.014H5.017l1.81 2.014h4.346zm-.725.805H7.552L9 15.545l1.448-1.61zM9.431 6.078V4.871h1.291v3.021H9.431v-.001l-.862-1.207v1.208H7.278v-3.02h1.291l.862 1.206zM1.636 0h14.728C17.267 0 18 .733 18 1.636v14.728c0 .903-.733 1.636-1.636 1.636H1.636A1.636 1.636 0 0 1 0 16.364V1.636C0 .733.733 0 1.636 0zM4.91 2.455v7.854h8.182V2.455H4.909z" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const Switch = () => (<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0zm-9 7A7 7 0 0 0 9 2v14z" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const Close = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59 7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12 5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"/>
  </svg>);
export const Enlarge = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M4 15c-.55 0-1 .45-1 1v3c0 1.1.9 2 2 2h3c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1-.45-1-1v-2c0-.55-.45-1-1-1zm1-9c0-.55.45-1 1-1h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.1 0-2 .9-2 2v3c0 .55.45 1 1 1s1-.45 1-1V6zm14-3h-3c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1 .45 1 1v2c0 .55.45 1 1 1s1-.45 1-1V5c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1h-2c-.55 0-1 .45-1 1s.45 1 1 1h3c1.1 0 2-.9 2-2v-3c0-.55-.45-1-1-1s-1 .45-1 1v2zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
  </svg>);
export const Previous = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M14.91 6.71a.996.996 0 0 0-1.41 0L8.91 11.3a.996.996 0 0 0 0 1.41l4.59 4.59a.996.996 0 1 0 1.41-1.41L11.03 12l3.88-3.88c.38-.39.38-1.03 0-1.41z"/>
  </svg>);
export const Next = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M9.31 6.71a.996.996 0 0 0 0 1.41L13.19 12l-3.88 3.88a.996.996 0 1 0 1.41 1.41l4.59-4.59a.996.996 0 0 0 0-1.41L10.72 6.7c-.38-.38-1.02-.38-1.41.01z"/>
  </svg>);
export const Menu = () => (<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M0 6h24v3H0zM0 15h29v3H0zM0 24h21v3H0z"/>
  </svg>);
export const Translate = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" stroke="currentColor" strokeWidth="0.8" d="M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0 0 14.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"/>
  </svg>);
export const Twitter = () => (<svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.62 231.59c94.34 0 145.94-78.16 145.94-145.94 0-2.22 0-4.43-.15-6.63A104.36 104.36 0 0 0 250 52.47a102.38 102.38 0 0 1-29.46 8.07 51.47 51.47 0 0 0 22.55-28.37 102.79 102.79 0 0 1-32.57 12.45c-15.9-16.906-41.163-21.044-61.625-10.093-20.461 10.95-31.032 34.266-25.785 56.873A145.62 145.62 0 0 1 17.4 37.81c-13.614 23.436-6.66 53.419 15.88 68.47A50.91 50.91 0 0 1 10 99.86v.65c.007 24.416 17.218 45.445 41.15 50.28a51.21 51.21 0 0 1-23.16.88c6.72 20.894 25.976 35.208 47.92 35.62a102.92 102.92 0 0 1-63.7 22A104.41 104.41 0 0 1 0 208.55a145.21 145.21 0 0 0 78.62 23" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const Youtube = () => (<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.624 4.514C18 5.95 18 8.948 18 8.948s0 2.997-.376 4.434a2.282 2.282 0 0 1-1.592 1.63c-1.403.384-7.032.384-7.032.384s-5.629 0-7.032-.385a2.282 2.282 0 0 1-1.592-1.629C0 11.945 0 8.948 0 8.948S0 5.95.376 4.514a2.281 2.281 0 0 1 1.592-1.629C3.37 2.5 9 2.5 9 2.5s5.629 0 7.032.385a2.281 2.281 0 0 1 1.592 1.629zM7.159 11.67l4.705-2.722-4.705-2.722v5.444z" fill="currentColor" fillRule="evenodd"/>
  </svg>);
export const ArrowNext = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
  </svg>);
export const Download = () => (<svg viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.353 8.101l.902-.901 3.573 3.559V3h1.253v7.759L13.624 7.2l.932.901-5.101 5.101-5.102-5.1zM4 13.447h1.09v1.09h8.728v-1.09h1.091v2.181H4v-2.181z" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const OtherLink = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M17 7h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c1.65 0 3 1.35 3 3s-1.35 3-3 3h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-9 5c0 .55.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1H9c-.55 0-1 .45-1 1zm2 3H7c-1.65 0-3-1.35-3-3s1.35-3 3-3h3c.55 0 1-.45 1-1s-.45-1-1-1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h3c.55 0 1-.45 1-1s-.45-1-1-1z"/>
  </svg>);
export const Weibo = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.082 19.61c3.973-.384 6.985-2.762 6.727-5.312-.258-2.548-3.687-4.303-7.66-3.92-3.973.385-6.986 2.762-6.728 5.31.258 2.55 3.688 4.305 7.66 3.922zm7.947-8.463c1.377.418 2.909 1.426 2.909 3.204 0 2.943-4.342 6.649-10.87 6.649C5.09 21 0 18.642 0 14.763c0-2.028 1.314-4.373 3.578-6.585 3.024-2.954 6.549-4.3 7.874-3.003.585.571.642 1.56.266 2.741-.196.595.571.265.571.267 2.444-1 4.575-1.059 5.354.029.416.58.376 1.393-.007 2.335-.177.434.055.502.393.6zm4.334-7.098a6.1 6.1 0 0 1 1.33 6.072l-.001.004a.924.924 0 0 1-1.157.577.912.912 0 0 1-.536-.445.879.879 0 0 1-.056-.686 4.34 4.34 0 0 0-.944-4.319 4.586 4.586 0 0 0-4.3-1.36.932.932 0 0 1-.874-.276.883.883 0 0 1-.192-.878.912.912 0 0 1 .682-.602c2.147-.445 4.472.205 6.048 1.913zm-2.42 2.135c.768.831.974 1.964.645 2.958a.795.795 0 0 1-.996.499.77.77 0 0 1-.509-.974 1.452 1.452 0 0 0-.316-1.448 1.538 1.538 0 0 0-1.44-.454.802.802 0 0 1-.77-.228.76.76 0 0 1-.168-.769.785.785 0 0 1 .608-.514 3.136 3.136 0 0 1 2.945.93zM10.3 14.993c.133-.234.047-.497-.193-.583-.236-.091-.532.02-.67.247-.135.228-.06.488.176.583.241.097.548-.015.687-.247zM9.035 16.58c.378-.605.179-1.297-.443-1.556-.616-.252-1.41.007-1.79.589-.385.584-.205 1.28.406 1.552.62.277 1.443.014 1.827-.585zm1.444-4.24c1.957.493 2.955 2.297 2.155 4.05-.81 1.792-3.143 2.747-5.122 2.124-1.91-.603-2.718-2.446-1.882-4.106.821-1.629 2.958-2.55 4.85-2.069z" fill="currentColor" fillRule="nonzero"/>
  </svg>);
export const Check = () => (<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
  </svg>);
export const ICON_MAP = {
    fb: Facebook,
    insta: Insta,
    naver: Naver,
    twitter: Twitter,
    youtube: Youtube,
    switch: Switch,
    close: Close,
    enlarge: Enlarge,
    previous: Previous,
    next: Next,
    menu: Menu,
    translate: Translate,
    arrowNext: ArrowNext,
    download: Download,
    other: OtherLink,
    weibo: Weibo,
    check: Check,
};
