"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * check if an incoming path is a content entry type (artist or notice)
 */
exports.checkIfEntry = ({ pathname, types, }) => {
    if (pathname.endsWith('/'))
        pathname = pathname.slice(0, -1);
    return pathname
        .split('/')
        .some((sub, i, subPaths) => types.includes(sub) && i !== subPaths.length - 1);
};
/**
 * Shuffle an array
 * https://stackoverflow.com/a/12646864/10340970
 * @param array
 */
exports.shuffle = (originArray) => {
    const array = [...originArray];
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
/**
 * Reorder an array based on a key
 */
exports.reorder = (origin, newOrder, key) => {
    const copy = Array.from(origin);
    const populateOrder = newOrder.reduce((result, ordered) => {
        const itemIndex = copy.findIndex(item => item[key] === ordered[key]);
        if (itemIndex < 0)
            return result;
        const item = copy.splice(itemIndex, 1);
        result = result.concat(item);
        return result;
    }, []);
    return populateOrder.concat(copy);
};
exports.detectIE = () => {
    if (typeof window === 'undefined')
        return false;
    const ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
};
exports.detectMobile = () => {
    if (typeof window === 'undefined')
        return false;
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};
