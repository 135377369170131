import * as React from 'react';
import styled from '@emotion/styled';
import { ICON_MAP } from './icons';
const StyledIcon = styled.span `
  color: ${({ color, theme }) => color || theme.fg};
  display: inline-block;
  svg {
    width: ${({ size }) => size || 1.125}rem;
    height: ${({ size }) => size || 1.125}rem;
    transform: rotate(${({ rotate }) => rotate}deg);
    transition: transform 0.3s ease;
  }
`;
export const Icon = ({ iconName, ...props }) => {
    const MatchedIcon = ICON_MAP[iconName];
    return (<StyledIcon {...props}>
      <MatchedIcon />
    </StyledIcon>);
};
