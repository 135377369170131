import React, { useLayoutEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import throttle from 'lodash/throttle';
// import throttle from 'lodash/throttle'
import { Nav } from './nav';
import { Footer } from './footer';
import { SocialMedia } from './social-media';
import { DecoyGrid } from './grid';
import { checkIfEntry } from '../utils';
const MaxWidth = styled.div `
  position: relative;
  width: 100vw;
  max-width: 80rem;
  margin: 0 auto;
  padding-bottom: 16rem;
  min-height: calc(100vh - 16rem);
`;
export const Layout = ({ location, children }) => {
    const [hideNav, shouldHideNav] = useState(true);
    const scrollRef = useRef(0);
    const scrollHandler = throttle(() => {
        const y = window.scrollY;
        const shouldHide = y > scrollRef.current && y > 100;
        shouldHideNav(shouldHide);
        scrollRef.current = y;
    }, 300);
    useLayoutEffect(() => {
        let { pathname } = location;
        // hide if current page is artist/notice
        const shouldHide = checkIfEntry({
            pathname,
            types: ['artist', 'notice'],
        });
        shouldHideNav(shouldHide);
        if (!shouldHide)
            window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [location]);
    return (
    // grid-size-root className is necessary to
    // perform sizing check
    <MaxWidth id="grid-size-root">
      <header>
        <Nav hideNav={hideNav} pathname={location.pathname}/>
      </header>
      <main>{children}</main>
      <SocialMedia hideNav={hideNav}/>
      <Footer />
      <DecoyGrid />
    </MaxWidth>);
};
