import * as React from 'react';
import styled from '@emotion/styled';
import { Icon } from './icons';
const StyledButton = styled.button `
  background: transparent;
  vertical-align: middle;
  display: inline-block;
  border: none;
  color: inherit;
  padding: 0.5rem;
  margin: 0;
`;
export const IconButton = ({ iconName, size = 2, color, ...props }) => (<StyledButton {...props}>
    <Icon color={color} size={size} iconName={iconName}/>
  </StyledButton>);
