const dark = {
    fg: '#fff',
    bg: '#010D1E',
    line: 'rgba(255, 255, 255, 0.12)',
    line2: 'rgba(255, 255, 255, 0.2)',
    inputFg: 'rgba(255, 255, 255, 0.6)',
    inputBg: 'rgba(255, 255, 255, 0.05)',
};
const light = {
    fg: '#010D1E',
    bg: '#fff',
    line: 'rgba(1, 13, 30, 0.08)',
    line2: 'rgba(0, 0, 0, 0.2)',
    inputFg: 'rgba(0, 0, 0, 0.6)',
    inputBg: 'rgba(0, 0, 0, 0.05)',
};
export const theme = {
    light,
    dark,
};
