import * as React from 'react';
import pose from 'react-pose';
import styled from '@emotion/styled';
import { TRANSITION, MenuUl } from './nav.common';
const Div = pose.div({
    hidden: {
        opacity: 0,
        staggerChildren: 25,
        transition: TRANSITION,
    },
    show: {
        opacity: 1,
        staggerChildren: 25,
        transition: TRANSITION,
        delay: 150,
    },
});
export const PoseContainer = styled(Div) `
  .mobile-nav {
    display: none;
  }
`;
export const DesktopNav = ({ hideNav, ...props }) => {
    return (<PoseContainer {...props} pose={hideNav ? 'hidden' : 'show'}>
      <MenuUl />
    </PoseContainer>);
};
