import * as React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby'
import { mqs } from '../style-utils';
import { DesktopNav } from './nav.desktop';
import { MobileNav } from './nav.mobile';
import { Logo } from '../logo';
import { useZone } from 'use-zone-breakpoints';
import { Link } from '../wrapper-lang';
import { usePositions } from '../wrapper-positions';
const StyledLogo = styled(Link) `
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
`;
const StyledNav = styled.nav `
  position: fixed;
  top: 0;
  left: 1.25rem;
  z-index: 1000;

  & .nav-wrapper {
    margin-top: 8rem;
  }

  ${mqs[0]} {
    & .nav-wrapper {
      margin-top: 6rem;
    }
  }

  ${mqs[1]} {
    & .nav-wrapper {
      margin-top: 6rem;
    }
  }
`;
export const Nav = ({ hideNav, pathname }) => {
    const zone = useZone();
    // change position & shuffle artist order
    const { shuffleOrder, changePosition } = usePositions();
    const handleClick = e => {
        if (!['/', '/en/', '/ja/', '/zh/', '/ko/'].includes(pathname))
            return;
        e.preventDefault();
        changePosition(zone);
        shuffleOrder();
    };
    return (<StyledNav>
      <StyledLogo onClick={handleClick} to="/" className="nav-logo">
        <Logo />
      </StyledLogo>
      <div className="nav-wrapper">
        {zone >= 2 ? (<DesktopNav className="nav-desktop" hideNav={hideNav}/>) : (<MobileNav className="nav-mobile" hideNav={hideNav}/>)}
      </div>
    </StyledNav>);
};
